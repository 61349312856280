import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../../src/components/graphql-error-list'
import SEO from '../../src/components/seo'
import Layout from '../../src/components/layout'
import SectionsChooser from '../../src/components/sections-chooser'

export const query = graphql`
  {
    sanityChecklist {
      id
      title
      metaTitle
      metaDescription
      contentSections {
        ... on SanityContentSection {
          _key
          _type
          _rawSectionContent(resolveReferences: { maxDepth: 5 })
          sectionSettings {
            collapsibleSection
            highlightedTitle
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanityHierarchicalSection {
          _key
          _type
          description
          slug {
            current
          }
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
      }
    }
  }
`

const ChecklistPage = props => {
  const { errors, data } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const sanityChecklist = data.sanityChecklist
  const checklistItems = sanityChecklist.contentSections
  let title = sanityChecklist.title
  let metaTitle = sanityChecklist.metaTitle || title
  const metaDescription = sanityChecklist.metaDescription

  return (
    <Layout title={title} {...props}>
      <SEO title={metaTitle} description={metaDescription} />
      {checklistItems ? <SectionsChooser sections={checklistItems} /> : null}
    </Layout>
  )
}

export default ChecklistPage
